<template>
    <div>
      <div class='main-loader' v-if='isLoading'></div>
      <div class="product-page" :class="{ 'delete-mode': isDeleteMode }">
        <!-- Access Denied Modal -->
      <b-modal
        id="access-denied-modal"
        v-model="showAccessDeniedModal"
        title="Access Denied"
        hide-footer
        centered
      >
        <p>Sorry, you don't have access to this agent yet! Please contact the creator to request access.</p>
      </b-modal>

          <div v-if="hasAccess">
            <b-container>
              <search-bar 
  :prompts="prompts"
  :showSwiper="false" 
  @search-input="debouncedGenerateImages"
  @delete-mode-change="handleDeleteMode"
  @confirm-delete="handleImageDeletion"
  :isDeleteMode="isDeleteMode"
  :selectedImagesCount="selectedImages.length"
/>
          <div v-if="prevImages.length">
            <b-row>
              <product-card 
  v-for="(product, index) in prevImages" 
  :key="`${product.id}-${index}`" 
  :product="product" 
  @toggle-like="updateLikeStatus" 
  :showShareButton="false"
  :showDeleteButton="false"
  :showChatButton="true"
  :src="product.image_url"
  :isDeleteMode="isDeleteMode"
  :isSelected="selectedImages.includes(product.id)"
  @toggle-selection="toggleImageSelection"
/>
            </b-row>
          </div>
          
          <div class="no-data mt-5 mb-5" v-else-if="!prevImages.length && !isLoading">
            <div class="mx-auto text-center">
              <h2>{{ noDataMessage }}</h2>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>
  
  
    
  <script>
  import SearchBar from '@/views/Website/imageListing/searchBar.vue';
  import ImageGallery from '@/views/Website/imageListing/ImageGallery.vue';
  import productCard from '@/views/Website/imageListing/imageCard.vue';
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { EventBus } from '@/event-bus'; 
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'; // Import Swiper components
  import 'swiper/css/swiper.css'; // Import Swiper styles
  import axios from 'axios'

  function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this, args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

  export default {

    metaInfo() {
    const agentId = this.$route.params.id;
    // Get agent details for better meta information
    const pageTitle = this.agentDetails 
      ? `Create ${this.agentDetails.productType || 'Product'} Images` 
      : 'Create Product Images';
    const pageDescription = this.agentDetails
      ? `Transform your ${this.agentDetails.productType || 'product'} photos into professional marketing visuals using BRIKS.AI's custom creative agent.`
      : 'Transform product photos into professional marketing visuals with BRIKS.AI advanced image processing.';
    const pageUrl = `https://briks.ai/agent/${agentId}`;

    return {
      title: pageTitle,
      titleTemplate: '%s | BRIKS.AI',
      meta: [
        // Primary meta tags
        { name: 'description', content: pageDescription },
        { name: 'keywords', content: `${this.agentDetails?.productType || 'product'} photography, AI image generation, marketing visuals, ${this.agentDetails?.purpose || 'product marketing'}` },
        
        // Security headers
        { name: 'robots', content: 'noindex, nofollow' },
        { name: 'referrer', content: 'strict-origin-when-cross-origin' }, // More specific referrer policy
        
        // Cache control
        { 'http-equiv': 'Cache-Control', content: 'private, no-cache, no-store, must-revalidate' },
        { 'http-equiv': 'Pragma', content: 'no-cache' },
        { 'http-equiv': 'Expires', content: '0' },
        
        // Security headers
        { 'http-equiv': 'X-Frame-Options', content: 'DENY' },
        { 'http-equiv': 'X-Content-Type-Options', content: 'nosniff' },
        
        // Viewport
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0' },
        
        // OpenGraph (minimal for security)
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'BRIKS.AI Image Creator' },
        { property: 'og:description', content: 'Create professional product images' }
      ],
      link: [
        { rel: 'canonical', href: pageUrl }
      ],
      // Minimal structured data for interaction page
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "BRIKS.AI Image Creator",
            "applicationCategory": "Photo Editor",
            "operatingSystem": "Any",
            "description": pageDescription,
            "offers": {
              "@type": "Offer",
              "category": "Product Photography"
            }
          }
        }
      ]
    };
  },

    // Authentication check
    beforeCreate() {
    // Check authentication
    const userData = localStorage.getItem('userData');
    if (!userData) {
        this.$router.push({
            name: 'login',
            query: { 
                redirect: `/agent/${this.$route.params.id}`,
                message: 'Please login to access the image creation tool'
            }
        });
        return;
    }

    // Parse and validate user data
    try {
        const parsedUserData = JSON.parse(userData);
        if (!parsedUserData.userId) {
            throw new Error('Invalid user data');
        }
    } catch (error) {
        localStorage.removeItem('userData');
        this.$router.push('/login');
    }
},

beforeRouteEnter(to, from, next) {
    if (!localStorage.getItem('userData')) {
        next('/login');
    } else {
        next(vm => {
            vm.resetState();
            vm.fetchAgentDetails();
        });
    }
},

beforeRouteUpdate(to, from, next) {
  this.resetState(); // Reset state when route changes
  next();
},
    components: {
      SearchBar,
      ImageGallery,
      productCard,
      Swiper,
    SwiperSlide,
    },
    data() {
      return {
        isLoading: false,
        prevImages: [],
        noDataMessage: 'Enter prompt to start generating images...',
        initialLoadingComplete: false,
        guestRequestCount: parseInt(localStorage.getItem('guestRequestCount')) || 0, // Initialize from localStorage
        currentPage: '', 
        prompt: '',
        prompts: [],
        agentDetails: null,
        showAccessDeniedModal: false,
        hasAccess: false,
        seed: '',
        agentType:'',
        purpose: '',
      productType: '', // For storing product type
      productName: '', // For storing product name
      influencerGender: '', // For storing influencer gender
      influencerName: '', // For storing influencer name
      isDeleteMode: false,
    selectedImages: [],
      };
    },
    created() {
      this.$store.dispatch('app/syncUserFromStorage').then(() => {
    if (!this.agentDetails) {
      this.fetchAgentDetails();
    }
  });

  // Retrieve seed from localStorage
  const savedSeed = localStorage.getItem('savedSeed');
  if (savedSeed) {
    this.seed = savedSeed;
  }
  this.debouncedGenerateImages = debounce(this.generateImages, 300);
  EventBus.$on('generate-images', this.generateImages);
},
    computed: {
      ...mapState('app', {
        // ...mapState('images', ['images']),
      }),
      ...mapGetters('app', ['isLogged', 'userData']),
      userId() {
        return this.userData?.userId || null;
      },
      agentId() {
    return this.$route.params.agentId;
  },
  isAdmin() {
    return ['8Bdnm4EsXDV7WfWQXGc0YfJ3Jc23', 'oFUFmV6CqQf3Kngpk2sUPMEFBL82'].includes(this.userId);
  }
    },
    watch: {
      isLogged(newValue) {
        if (newValue) {
          // Reset guest request count when user logs in
          this.guestRequestCount = 0;
          localStorage.removeItem('guestRequestCount');
          this.fetchImagesByPage();
          localStorage.savedSeed
        }
      },
      $route(to, from) {
        this.fetchImagesByPage(); // Automatically fetch images when the route changes
      }
    },
    mounted() {
      window.addEventListener('scroll', this.onScroll);
      if (this.isLogged) {
        this.fetchImagesByPage(this.currentPage);
      } else {
        this.initialLoadingComplete = true;
      }
      this.currentPage = this.$route.name;
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.onScroll); // Clean up the event listener
      EventBus.$off('generate-images', this.generateImages);
      this.cleanupEventSource();
    },
  
    methods: {
      resetState() {
    this.isLoading = false;
    this.prevImages = [];
    this.noDataMessage = 'Enter prompt to start generating images...';
    this.initialLoadingComplete = false;
    this.prompt = '';
    this.prompts = [];
    this.agentDetails = null;
    this.showAccessDeniedModal = false;
    this.hasAccess = false;
    this.seed = '';
  },

      ...mapActions('app', [
        'syncUserFromStorage',
      ]),
      async fetchAgentDetails() {
      try {
      const url = new URL(`https://api.briks.ai/api/agents/${this.agentId}`);
      const response = await axios.get(url.toString());
      this.agentDetails = response.data;
      this.agentType = this.agentDetails.agentType || '';
      this.productType = this.agentDetails.productType || '';
      this.productName = this.agentDetails.productName || ''; 
      this.influencerGender = this.agentDetails.influencerGender|| ''; 
      this.influencerName = this.agentDetails.influencerName|| ''; 
      
         // Store prompts from agent details
         if (Array.isArray(this.agentDetails.savedPrompts)) {
         this.prompts = this.agentDetails.savedPrompts;
       } else {
        //  console.warn('Prompts data is not an array:', this.agentDetails.savedPrompts);
         this.prompts = [];
       }
       
        // Check agent access
      const { accessType, createdBy, sharedUsers } = this.agentDetails;
       // If user is not logged in, prompt them to log in
       if (!this.userId) {
        this.$bvModal.show('modal-login');
        return;
      }
      if (accessType === 'private') {
        if (this.userId === createdBy || (sharedUsers && sharedUsers.includes(this.userId))) {
          this.hasAccess = true;
        } else {
          this.hasAccess = true;
          // User does not have access
          this.showNoAccessModal();
        }
      } else {
        // If accessType is not private, everyone has access
        this.hasAccess = true;
      }
    } catch (error) {
      console.error('Error fetching agent details:', error);
      // Handle error as needed
      this.hasAccess = false;
      // Optionally show an error message or redirect
    }
  },
  
  
  showNoAccessModal() {
    this.showAccessDeniedModal = true;
  },
  
    async generateImages(prompt) {
  try {
    // Initial validation
    const userId = this.userId;
    if (!userId) {
      this.$bvModal.show('modal-login');
      return;
    }

    if (!this.agentDetails) {
      await this.fetchAgentDetails();
    }

    // Setup parameters
    const { workflow, lora, trigger, agentType, purpose, dataset,productType, productName, influencerGender, influencerName   } = this.agentDetails;
    const count = 4;
    if (!this.seed) {
      this.seed = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    }

    const finalPrompt = trigger ? `${trigger}, ${prompt}` : `${prompt}`;

    const params = new URLSearchParams({
      userId: userId,
      agentId: this.agentId,
      tenantId: 378,
      prompt: finalPrompt,
      seed: this.seed,
      workflow: workflow || 'lora',
      lora: lora || '',
      trigger: trigger || lora || '',
      count: count,
      agentType: agentType || '',
      purpose: purpose || '',
      dataset: dataset || '',
      productType: productType || '',
      productName: productName || '',
      influencerGender: influencerGender || '',
      influencerName: influencerName || '',
    }).toString();

    // Initialize generation state
    this.initializeGeneration();

    // Create and setup EventSource
    await this.setupEventSource(`https://api.briks.ai/web/generate-images?${params}`, prompt);

  } catch (error) {
    console.error('Error in generateImages:', error);
    this.handleGenerationError(error);
  }
},initializeGeneration() {
    this.isLoading = true;
    EventBus.$emit('update-generating', true);
    this.showToast('Image generation started.', 'info');

    // Cleanup any existing EventSource
    this.cleanupEventSource();
  },

  cleanupEventSource() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  },

  async setupEventSource(url, prompt) {
    this.eventSource = new EventSource(url);

    // Connection opened handler
    this.eventSource.onopen = this.handleSSEOpen;

    // Message handler
    this.eventSource.onmessage = (event) => this.handleSSEMessage(event, prompt);

    // Error handler
    this.eventSource.onerror = this.handleSSEError;
  },

  handleSSEOpen() {
    console.log('SSE Connection established');
  },

  async handleSSEMessage(event, prompt) {
    try {
      const data = JSON.parse(event.data);

      if (data.type === 'error') {
        throw new Error(data.error);
      }

      if (data.type === 'complete' || data.type === 'close') {
        await this.handleGenerationComplete();
        return;
      }

      if (data.success) {
        if (data.message === 'Image created') {
          await this.handleSingleImage(data.image, prompt);
        } else if (data.message === 'completed') {
          await this.handleMultipleImages(data.images, prompt);
        }
      } else {
        console.error('Error in SSE data:', data);
        throw new Error(data.error || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error processing SSE message:', error);
      this.handleGenerationError(error);
    }
  },

  async handleSingleImage(imageUrl, prompt) {
    const imageId = this.extractImageId(imageUrl);
    await this.addNewImage(imageId, imageUrl, prompt);
  },

  async handleMultipleImages(imageUrls, prompt) {
    for (const imageUrl of imageUrls) {
      const imageId = this.extractImageId(imageUrl);
      if (!this.imageExists(imageId)) {
        await this.addNewImage(imageId, imageUrl, prompt);
      }
    }
    await this.updateExecutionCount();
  },

  extractImageId(imageUrl) {
    return imageUrl.split('/').pop().split('.').shift();
  },

  imageExists(imageId) {
    return this.prevImages.some((img) => img.id === imageId);
  },

  async addNewImage(imageId, imageUrl, prompt) {
    const newImage = {
      id: imageId,
      image_url: imageUrl,
      prompt: prompt,
      like_or_dislike: 'N',
      agentId: this.agentId,
      seed: this.seed,
    };

    this.prevImages.unshift(newImage);
    // await this.saveImageToDB(newImage);
  },

  async handleGenerationComplete() {
    this.cleanupEventSource();
    this.isLoading = false;
    EventBus.$emit('update-generating', false);
    this.showToast('Image generation completed.', 'success');
  },

  handleSSEError(error) {
    console.error('SSE Error:', error);
    // Check if the connection is closed
    if (this.eventSource?.readyState === EventSource.CLOSED) {
      console.log('SSE connection closed normally');
      this.handleGenerationComplete();
    } else {
      this.handleGenerationError(error);
    }
  },

  handleGenerationError(error) {
    console.error('Generation error:', error);
    this.cleanupEventSource();
    this.isLoading = false;
    EventBus.$emit('update-generating', false);
    this.showToast(error.message || 'Image generation failed. Please try again.', 'error');
  },
    // async saveImageToDB(image) {
    //   try {
    //     const { id, image_url, prompt, agentId } = image;
    //     const userId = this.userId;
    //     const seed = this.seed;
    //     const tenantId = this.userData?.tenantId || '378';
    //     const agentType = this.agentType;
    //     const productType = this.productType;

    //     await axios.post(`${process.env.VUE_APP_BASE_URL}saveImage`, {
    //       id,
    //       userId,
    //       tenantId,
    //       imageUrl: image_url,
    //       prompt,
    //       agentId,
    //       seed,
    //       agentType: agentType || '',
    //       productType: productType || '',
    //     });
    //   } catch (error) {
    //     console.error('Error saving image to DB:', error);
    //   }
    // },

    async updateExecutionCount() {
      try {
        const userId = this.userId;
        const agentId = this.agentId;

        await axios.patch(`${process.env.VUE_APP_BASE_URL}agents/runupdate/${agentId}`, {
          userId: userId,
          incrementBy: 1,
          executionDetails: {
          prompt: this.prompt,
          imageStyle: this.selectedStyle,
          seed: this.seed,
          agentType: this.agentType,
          },
        });
      } catch (error) {
        console.error('Error updating execution count:', error);
      }
    },
  
  
    async fetchImages(pageSize = 20, continuationToken = null) {
      this.isLoading = true;

      try {
        const userId = this.userId;
        const agentId = this.agentId;

        if (userId) {
          const params = {
            userId: userId,
            agentId: agentId,
            pageSize: pageSize,
          };

          if (continuationToken) {
            params.continuationToken = continuationToken;
          }

          const response = await axios.get(`${process.env.VUE_APP_BASE_URL}getImages`, { params });

          const data = response.data;

          if (Array.isArray(data.images)) {
            if (data.images.length === 0) {
              this.continuationToken = null;
              return;
            }

            this.prevImages.push(...data.images);
            this.continuationToken = data.continuationToken;
            this.initialLoadingComplete = true;

            // await saveImagesToIndexedDB(data.images);
          }
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchImagesByPage() {
      await this.fetchImages();
    },

    onScroll() {
      const bottomOfWindow =
        window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 100;
      if (bottomOfWindow && !this.isLoading) {
        this.fetchMoreImages();
      }
    },

    async fetchMoreImages() {
      if (!this.continuationToken || this.isLoading) return;
      await this.fetchImages(20, this.continuationToken);
    },
    
     
  showToast(message, type = 'success') {
    // Use the ToastificationContent component for notifications
    this.$toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon:
          type === 'success'
            ? 'CheckIcon'
            : type === 'error'
            ? 'AlertTriangleIcon'
            : 'InfoIcon',
        variant: type,
      },
    });
  },

  async updateLikeStatus(imageId, likeStatus) {
  const userId = this.userData?.userId;

  if (!userId) {
    this.$bvModal.show("modal-login");
    return;
  }

  // Find the image in the prevImages array
  const image = this.prevImages.find(img => img.id === imageId);

  try {
    const response = await fetch(`${process.env.VUE_APP_BASE_URL}updateLikeStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, imageId, likeStatus }),
    });

    const data = await response.json();

    if (data.message === 'Like status updated successfully!') {
      const updatedImage = data.updatedImage;
      
      if (image) {
        image.like_or_dislike = updatedImage.like_or_dislike;

        if (updatedImage.like_or_dislike === 'Y') {
          // Save to local state and localStorage
          this.seed = updatedImage.seed;
          localStorage.setItem('savedSeed', this.seed);

          // Use the updatedImage data to update the agent
          await this.updateAgentData(updatedImage.imageUrl, updatedImage.seed, updatedImage.prompt);
        } else {
          localStorage.removeItem('savedSeed');
        }
      }
    }
  } catch (error) {
    console.error('Error updating like status:', error);
  }
},

handleDeleteMode(isDeleteMode) {
    // console.log('Toggling delete mode:', isDeleteMode);
    this.isDeleteMode = isDeleteMode;
    if (!isDeleteMode) {
      this.selectedImages = [];
    }
  },

  toggleImageSelection(imageId) {
    // console.log('Toggling image selection:', imageId);
    const index = this.selectedImages.indexOf(imageId);
    if (index === -1) {
      this.selectedImages.push(imageId);
    } else {
      this.selectedImages.splice(index, 1);
    }
  },

  async handleImageDeletion() {
  if (!this.isLogged) {
    this.$bvModal.show("modal-login");
    return;
  }

  if (!this.selectedImages.length) return;

  const isAdmin = ['8Bdnm4EsXDV7WfWQXGc0YfJ3Jc23', 'oFUFmV6CqQf3Kngpk2sUPMEFBL82'].includes(this.userId);
  const confirmMessage = isAdmin 
    ? 'You are deleting images as an administrator. This action cannot be undone.'
    : 'Are you sure you want to delete the selected images? This action cannot be undone.';

  const confirmed = await this.$bvModal.msgBoxConfirm(confirmMessage, {
    title: 'Confirm Deletion',
    // okVariant: 'danger',
    okTitle: 'Yes, Delete Now',
    cancelTitle: 'Cancel',
    centered: true
  });

  if (!confirmed) return;

  this.isLoading = true;
  try {
    const response = await fetch(`${process.env.VUE_APP_BASE_URL}deleteImages`, { // Updated endpoint
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: this.userId,
        imageIds: this.selectedImages
      }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.error || 'Failed to delete images');
    }

    // Remove the deleted images from the local state
    this.prevImages = this.prevImages.filter(img => !this.selectedImages.includes(img.id));

    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'Success',
        text: `${result.adminAction ? '[Admin] ' : ''}${this.selectedImages.length} image${this.selectedImages.length > 1 ? 's' : ''} deleted successfully`,
        icon: 'CheckIcon',
        variant: 'success',
      },
    });

  } catch (error) {
    console.error('Error deleting images:', error);
    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'Error',
        text: error.message || 'Failed to delete images. Please try again.',
        icon: 'XIcon',
        variant: 'danger',
      },
    });
  } finally {
    this.isLoading = false;
    this.isDeleteMode = false;
    this.selectedImages = [];
  }
},

async updateAgentData(imageUrl, seed, prompt) {
  try {
    const agentId = this.agentId;

    const response = await axios.put(`${process.env.VUE_APP_BASE_URL}agents/${agentId}`, {
      imageUrls: [imageUrl], // Add this image URL
      savedPrompts: [
        {
          prompt: prompt,
          seed: seed,
        },
      ],
    });

    if (response.status === 200) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: 'Added to favorites!',  // Fixed spelling of 'favorite'
      text: 'Image has been added to your favorite collection',
      icon: 'CheckIcon',
      variant: 'success'
    },
    timeout: 5000  // Set to 5 seconds
  });
} else {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: 'Update Failed',
      text: 'Failed to add image to favorites. Please try again.',
      icon: 'XIcon',
      variant: 'danger'
    },
    timeout: 5000  // Set to 5 seconds
  });
}
} catch (error) {
  console.error('Error updating agent data with liked image:', error);
  this.$toast({
    component: ToastificationContent,
    props: {
      title: 'Error Occurred',
      text: 'Unable to add image to favorites. Please try again later.',
      icon: 'AlertTriangleIcon',
      variant: 'danger'
    },
    timeout: 5000  // Set to 5 seconds
  });
}
},
    },

    onImageStyleSelected(selectedStyle) {
    this.imageStyle = selectedStyle;
  },
    
  };
  </script>
  
    
    <style scoped>
    .search-input {
      border-radius: 0rem !important;
    }
    </style>
    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    
  
    .swiper-slide {
      display: flex;
      align-items: 'auto';
      justify-content: center;
      padding: 2px !important;
      padding-bottom: 15px !important;
     
      box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  }
  
  
  .swiper-container {
      width: 100%;            /* Ensures the swiper takes full width */
      overflow-x: auto;       /* Enables horizontal scrolling for the container */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
      scrollbar-width: none;  /* Hides scrollbar in Firefox */
      -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
  }
  
  .swiper-container::-webkit-scrollbar {
      display: none;  /* Hides scrollbar in WebKit browsers */
  }
  .seed-controls {
  margin-top: 0em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.seed-controls .seed-info {
  margin-left: 1em;
  font-weight: bold;
}

.product-page {
  &.delete-mode {
    .product-card {
      cursor: pointer;
      position: relative;
    }
  }
}

.selection-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: none;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 10px;
  z-index: 10;
  transition: background-color 0.2s ease;

  &.visible {
    display: flex;
  }

  &.selected {
    background-color: rgba(0, 123, 255, 0.1);
  }
}

.selection-checkbox {
  background-color: white;
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.checkbox-icon {
  color: #adb5bd;

  &.selected {
    color: #007bff;
  }
}
  
    </style>
    